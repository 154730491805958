<template>
  <div>

    <el-form label-width="90px">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="试卷ID：">
            {{list.edu_paper_id}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷名称：">
            {{list.paper_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷科目：">
            {{list.subject_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷地区：">
            {{list.dis_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷年份：">
            {{list.str_post?list.str_post.questionYear:''}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷来源：">
            {{list.source_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷总分：">
            {{list.paper_score}}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                     :list="list.questions" />

    <scrollTo ref="scrollToRef"
              :list="childredList" />
  </div>
</template>

<script>
// import tipicBody from '@/components/topic/index.vue'
import scrollTo from '@/components/scrollTo'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'


export default {
  data () {
    return {
      recordEduPaper: {},
      list: [],
      isActive: 0,
      domList: [],
      childredList: 0

    }
  },
  components: {
    scrollTo, BASETOPICCOMMON
  },
  mounted () {
    this.getInfo()
    document.body.background = '#F6F6F6'
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v2/public/show_paper',
        method: 'get',
        params: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.recordEduPaper = res.data.recordEduPaper
        this.list = res.data
        setTimeout(() => {
          var oFixed = document.getElementsByClassName('yuan')
          this.childredList = oFixed.length
        }, 1000);
      })
    },


    toMaodian (index) {
      this.isActive = index
      let anchorElement = document.getElementById('maodian' + index);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.testingCentre_warp {
  // height: 86vh;
  // overflow-y: auto;
  // overflow-x: hidden;
}
::v-deep .view_warp[data-v-fae5bece] {
  background: #f6f6f6;
}
.sort {
  cursor: pointer;
  margin-right: 10px;
  color: #409eff;
}
.check {
  cursor: pointer;
  color: #409eff;
}
.btn {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding-bottom: 30px;
}
::v-deep .el-loading-mask {
  z-index: 2010 !important;
}
.form_warp {
  background: #f6f6f6;
  .el-form-item {
    margin-bottom: 0px;
    color: #000000;
    font-weight: bold;
    .el-form-item__label {
      color: #999999;
    }
  }
}
.tipicBody_class {
  margin-top: 20px;
  position: relaive;
  margin-right: 130px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>